





























































// TODO(Andrew): uncomment when time comes
// @ts-nocheck
import moment from "moment"
import type { PropType } from "vue"
import Vue from "vue"

import type { Game } from "@/types/game"

import { RtbButton, RtbLink, RtbTable } from "@/components/ui"
const GameSettings = () => import("@/components/Game/GameSettings.vue")

import { Game as GameEntity } from "../../helpers"

export default Vue.extend({
  name: "AdminGames",
  components: {
    RtbButton,
    RtbLink,
    RtbTable,
    GameSettings
  },
  props: {
    time: {
      type: String as PropType<"past" | "future">,
      required: true,
      validator: val => ["past", "future"].indexOf(val) !== -1
    }
  },
  data() {
    return {
      pagination: {
        rowsPerPage: 20
      },
      gameToEdit: null,
      editing: false,
      games: [] as Game[]
    }
  },
  computed: {
    tableHeaders() {
      const fields = [
        {
          text: "Name",
          value: "name",
          sortable: false
        },
        {
          text: "Host",
          value: "host",
          sortable: false
        },
        {
          text: "Players",
          value: "players",
          sortable: false
        },
        {
          text: "Start",
          value: "startTimestamp",
          sortable: true
        }
      ]

      if (this.time === "past") {
        fields.push(
          {
            text: "End",
            sortable: false
          },
          {
            text: "Duration, m",
            sortable: false
          }
        )
      }

      fields.push(
        {
          text: "Links",
          sortable: false
        },
        {
          text: "Action",
          sortable: false
        }
      )

      return fields
    }
  },
  watch: {
    time() {
      this.games = []
      this.getGames()
    }
  },
  async created() {
    this.getGames()
  },
  methods: {
    async getGames() {
      const { time } = this
      const { orgID } = this.$store.state

      if (time === "past") {
        this.games = await this.$services
          .get("game")
          .then(service => service.getCompletedGames(orgID))
      }

      if (time === "future") {
        this.games = await this.$services
          .get("game")
          .then(service => service.getFutureGames(orgID))
      }
    },
    getHostName(userID) {
      return this.HostsProvider_raw.find(host => host.id === userID)?.firstname
    },
    getDuration(game) {
      return Math.round(
        moment.duration(GameEntity.getDuration(game)).asMinutes()
      )
    },
    async pushToGameEdit({ theKey: gameID, clientID }) {
      if (!gameID) throw new Error("No game ID")
      if (!clientID) throw new Error("No client ID")
      await this.$store.dispatch("auth/initializeToGame", { gameID, clientID })
      await this.$router.push(`/game/${clientID}/settings`)
    },
    getStartDate(game) {
      if (game.startTimestamp !== undefined && game.startTimestamp !== 0) {
        return moment(game.startTimestamp).format("LLL")
      } else {
        return 0
      }
    },
    getEndDate(game) {
      if (game.endTimestamp !== undefined && game.endTimestamp !== 0) {
        return moment(game.endTimestamp).format("LLL")
      } else {
        return 0
      }
    },
    editGameHandler(game) {
      this.gameToEdit = game
      this.editing = true
    },
    closeEditGameSettingsHandler() {
      this.gameToEdit = null
      this.editing = false
    }
  }
})
