var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("rtb-table"),
      _c("v-data-table", {
        staticClass: "rtb-table",
        attrs: {
          headers: _vm.tableHeaders,
          items: _vm.games,
          "item-key": "theKey",
          loading: _vm.games.length === 0,
          pagination: _vm.pagination,
        },
        on: {
          "update:pagination": function ($event) {
            _vm.pagination = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "items",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "td",
                  [
                    _c(
                      "rtb-link",
                      {
                        attrs: { href: "#", role: "button" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.pushToGameEdit(item)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.name))]
                    ),
                  ],
                  1
                ),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      item.hostUserID ? _vm.getHostName(item.hostUserID) : ""
                    )
                  ),
                ]),
                _c("td", [_vm._v(_vm._s(item.players))]),
                _c("td", [_vm._v(_vm._s(_vm.getStartDate(item)))]),
                _vm.time === "past"
                  ? [
                      _c("td", [_vm._v(_vm._s(_vm.getEndDate(item)))]),
                      _c("td", [_vm._v(_vm._s(_vm.getDuration(item)))]),
                    ]
                  : _vm._e(),
                _c(
                  "td",
                  { attrs: { width: "96px" } },
                  [
                    _c(
                      "rtb-link",
                      {
                        attrs: {
                          href: "/gamelogin/" + item.clientID + "?auth=0",
                          target: "_blank",
                        },
                      },
                      [_vm._v(" Login ")]
                    ),
                    _c("br"),
                    _c(
                      "rtb-link",
                      {
                        attrs: {
                          href:
                            "/gamelogin/" + item.clientID + "?audit=1&auth=0",
                          target: "_blank",
                        },
                      },
                      [_vm._v(" Audit ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c(
                      "rtb-button",
                      {
                        attrs: { variant: "icon" },
                        on: {
                          click: function ($event) {
                            return _vm.editGameHandler(item)
                          },
                        },
                      },
                      [_c("svg-icon", { attrs: { name: "pen-regular" } })],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm.gameToEdit
        ? _c(
            "v-dialog",
            {
              attrs: {
                persistent: "",
                "content-class": "v-dialog--size--default",
              },
              model: {
                value: _vm.editing,
                callback: function ($$v) {
                  _vm.editing = $$v
                },
                expression: "editing",
              },
            },
            [
              _c("GameSettings", {
                attrs: { game: _vm.gameToEdit },
                on: { close: _vm.closeEditGameSettingsHandler },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }